import { sortedUniq } from "lodash-es";

export const slugRegex = /^[a-z]+-[a-z]+-[a-z]+$/;

/**
 * Extract all slugs in the form "name-adjective-noun" from the text, separated by whitespace or commas.
 *
 * Filters out duplicate slugs and returns them in sorted order.
 *
 * @example
 * getSlugsInText("Slugs: malena-upset-carp, jerrilee-spatial-puma caro-insufficient-gayal")
 * // => ["caro-insufficient-gayal", "jerrilee-spatial-puma", "malena-upset-carp"]
 */
export const getSlugsInText = (text: string) => {
  return sortedUniq(
    text
      .split(/[\s,\n]+/)
      .filter(Boolean)
      .filter((s) => slugRegex.test(s))
      .sort((a, b) => a.localeCompare(b))
  );
};
