import { AlertColor } from "@mui/material";
import type { ReactNode } from "react";
import { create } from "zustand";

export interface ConfirmationDialog {
  title: string;
  description: string | ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
}

export interface Notification {
  id: string;
  text: string;
  color: AlertColor;
}

export const useDialogs = create<{
  confirmation: ConfirmationDialog | null;
  setConfirmation: (confirmation: ConfirmationDialog | null) => void;
  notifications: Notification[];
  createNotification: (notification: Notification) => void;
  deleteNotification: (notification: Notification) => void;
}>((set, get) => ({
  confirmation: null,
  setConfirmation: (confirmation) => {
    set({
      confirmation,
    });
  },
  notifications: [],
  createNotification: (n) => {
    set({ notifications: [...get().notifications, n] });
  },
  deleteNotification: (n) => {
    set({
      notifications: get().notifications.filter((item) => item.id !== n.id),
    });
  },
}));

export const createNotification = (notification: Omit<Notification, "id">) => {
  const notif: Notification = {
    ...notification,
    id: crypto.randomUUID(),
  };
  useDialogs.getState().createNotification(notif);
  return () => {
    useDialogs.getState().deleteNotification(notif);
  };
};
